import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Aos from 'aos'

export class Animation {

  constructor() {

    this.config()

    Aos.init({
        offset: 250,
        easing: 'ease-out',
        once: true
      }
    )
  }

  config() {
    gsap.registerPlugin(ScrollTrigger);
  }
}

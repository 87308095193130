import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

export class GsapPanel {
  constructor() {
    this._panelContainer = document.querySelector('.c-gsap-panel')

    if (!this._panelContainer) {
      return;
    }

    this._panels = document.querySelectorAll('.c-gsap-panel-header-item');
    this._panelsContent = document.querySelectorAll('.c-gsap-panel-content-item');

    // this.init();
    this.clientInit();
  }

  init() {
    gsap.registerPlugin(ScrollTrigger);

    let totalHeight = 0;
    this._panels.forEach(panel => {
      totalHeight += panel.clientHeight;
    });

    let tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: ".c-gsap-panel",
          start: "center center",
          pin: true,
          scrub: 1,
          end: `bottom+=${totalHeight * 1.5}px`,
        }
      });

    this._panels.forEach((panel, index) => {
      //current panel animation
      // tl.to(panel.querySelector('.c-gsap-panel-header-item__image'), {
      //   transform: "translate(-20px, 20px)"
      // }, index)
      tl.to(panel.querySelector('.c-gsap-panel-header-item__title'), {
        // transform: "translate(-20px, 20px)",
        opacity: 1
      }, index)
        .to(panel, {
          position: 'initial', zIndex: i => i
        }, index);


      if (index > 0) {
        //prev panel animation
        tl.to([
          this._panels[index - 1].querySelector('.c-gsap-panel-header-item__image'),
        ], {
          transform: "translate(-1000px, 0) rotate(-5deg)",
        }, index - 0.5)
          .to([
            this._panels[index - 1].querySelector('.c-gsap-panel-header-item__title'),
          ], {
            opacity: 0,
          }, index - 0.2)
          .to(this._panels[index - 1].querySelector('.c-gsap-panel-header-item__image'), {
            transform: "translate(-20px, 20px)",
          }, index)
          .to(this._panels[index - 1], {
            zIndex: (index + 1) * -2,
          }, index - 0.5)
          .to(this._panels[index - 1], {
            position: 'absolute',
          }, index)
        ;
      }
    });

    this._panelsContent.forEach((panel, index) => {
      tl.to(panel, {
        opacity: 1,
        transform: "translate(0, 0)"
      }, index);

      if (!(index === this._panelsContent.length - 1)) {
        tl.to(panel, {
          opacity: 0,
          transform: "translate(10px, 0)"
        }, index + 1)
      }
    })
  }

  clientInit() {
    this._panels.forEach(panel => {
      console.log(panel);
      panel.querySelector('.c-title').addEventListener('click', (e) => {
        let target = e.target;
        let parentContainer = target.closest('.c-gsap-panel-header-item');
        let index = Array.from(this._panels).indexOf(parentContainer);

        this._panelsContent.forEach(panel => {
          panel.classList.remove('active');
        })

        this._panels.forEach(panel => {
          if (panel.classList.contains('active')) {
            panel.classList.remove('active');
          }

          if (panel.classList.contains('is-active')) {
            panel.classList.remove('is-active');
          }
        })

        parentContainer.classList.add('active');
        parentContainer.classList.add('is-active');
        this._panelsContent[index].classList.add('active');
      })
    })
  }
}

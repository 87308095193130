import {LoaderIcon} from "@js/components/map/utils/Icons";

export default function Loader({loading}) {

  return (
    <div id="c-loader" hidden={!loading}>
      {LoaderIcon}
    </div>
  )
}

export function convertArrayToParams(array) {
  let params = '';

  if (array.length === 0) {
    return '';
  }

  Object.entries(array).map((value, key) => {
    if (value[1] === '') {
      delete array[value[0]]
    }

    let values = Object.entries(value[1]).map((item, key) => {
      return item[1].slug
    })

    params = params + '&' + value[0] + '=' + values.join(',')
  });

  return params;
}

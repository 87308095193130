import {CountUp} from 'countup.js';

export class Count {
  constructor() {
    this._els = document.querySelectorAll('*[data-countup-number]');

    if (!this._els.length) {
      return;
    }

    this.init();
  }

  init() {

    let options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this._els.forEach(el => {
            const countUp = new CountUp(el.getAttribute('id'), el.getAttribute('data-countup-number'));
            countUp.start(() => console.log('Complete !'));
          })
          observer.disconnect();
        }
      });
    }, options);

    let elCount = document.querySelector('.s-number-count');
    observer.observe(elCount);
  }
}

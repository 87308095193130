import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {createRoot} from "react-dom";
import App from "@js/components/map/app";
import {Count} from "@js/components/Count";
import {GsapPanel} from "@js/components/GsapPanel";
import {ArrowCustom} from "@js/components/ArrowCustom";
import {Archive} from "@js/components/Archive";
import {Tabs} from "@js/components/Tabs";
import {Slider} from "@js/components/Slider";
import {Animation} from "@js/utils/Animation";

window.onload = () => {

  new Animation();
  new Header()
  new Count()
  new GsapPanel()
  new ArrowCustom()
  new Archive()
  new Tabs()
  new Slider()

  const root = createRoot(document.getElementById('map'))
  root.render(<App/>)
}

import {useCallback, useState} from "react";

export default function useApiFetch(method, url) {

  const [loading, setLoading] = useState(null);
  const [res, setRes] = useState(null)

  const load = useCallback(async () => {
    setLoading(true);

    try {
      const response = await fetchApi(method, url)
      setRes(response)
      setLoading(false)
      return response
    } catch (e) {
      console.error(e, 'error');
    }
    setLoading(false)

  }, [url]);

  return {
    res,
    setRes,
    load,
    loading
  }
}

export const fetchApi = async (method, url) => {

  let args = {
    method,
  }

  const request = new Request(url, args);

  return fetch(request)
    .then(res => res.json())
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err)
    })
}

import Sidebar from "@js/components/map/components/Sidebar";
import Map from "@js/components/map/components/Map";
import FilterContext from "@js/components/map/context/FilterContext";
import {useEffect, useState} from "react";
import PostTypeContext from "@js/components/map/context/PostTypeContext";
import TransContext from "@js/components/map/context/TransContext";

export default function App() {
  const [filter, setFilter] = useState([]);
  const [posttype, setPosttype] = useState(null);
  const [isProgram, setIdProgram] = useState(false);
  const [title, setTitle] = useState(null);
  const [trans, setTrans] = useState(null);
  const [isClosed, setIsClosed] = useState(null);


  useEffect(() => {
    let container = document.querySelector("*[data-posttype]");

    if (container) {
      setPosttype(container.getAttribute("data-posttype"));
      setIdProgram(container.getAttribute("data-is-program"));
      setTitle(container.getAttribute("data-title"));
      setTrans(JSON.parse(container.getAttribute("data-trans")));
    }
  }, []);


  return (<>
    {posttype ? <div className="s-interactive-map-react">
      <TransContext.Provider value={trans}>
        <PostTypeContext.Provider value={[posttype, setPosttype]}>
          <FilterContext.Provider value={[filter, setFilter]}>
            <Sidebar title={title} isProgram={isProgram} updateClosed={(e) => setIsClosed(e)}/>
            <Map isClosed={isClosed}/>
          </FilterContext.Provider>
        </PostTypeContext.Provider>
      </TransContext.Provider>
    </div> : null}
  </>);
}

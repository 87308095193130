import {DegreeIcon, ScheduleIcon, UserIcon} from "@js/components/map/utils/Icons";
import {useContext} from "react";
import TransContext from "@js/components/map/context/TransContext";

export default function Item({value, onPress}) {
  const trans = useContext(TransContext);

  return (<div className="c-popup-accordeon-header" onClick={() => onPress(value)}>
    {/*{!value.is_programme ? (*/}
    {/*    <>*/}
    {/*      <div className="c-popup-accordeon-header__info">*/}
    {/*        {DegreeIcon}*/}
    {/*        <div className="label">{trans.map_popup_stage} :</div>*/}
    {/*        <span>{value.nb_stagiaire > 1 ? value.nb_stagiaire : 'n/a'}</span>*/}
    {/*      </div>*/}
    {/*      <div className="c-popup-accordeon-header__info">*/}
    {/*        {UserIcon}*/}
    {/*        <div className="label">{trans.map_popup_expert} :</div>*/}
    {/*        <span>{value.nb_experts > 1 ? value.nb_experts : 'n/a'}</span>*/}
    {/*      </div>*/}
    {/*      <div className="c-popup-accordeon-header__info">*/}
    {/*        {ScheduleIcon}*/}
    {/*        <div className="label">{trans.map_popup_count_day} :</div>*/}

    {/*        <span>{value.nb_h > 1 ? value.nb_h : 'n/a'}</span>*/}
    {/*      </div>*/}
    {/*    </>*/}
    {/*  )*/}
    {value.client ? <div className="c-popup-accordeon-header__info">
      <div className="label">{value.client.replace(/&amp;/g, "&")}
      </div>
    </div> : null}
    {value.sector || value.study ? <div className="c-popup-accordeon-header__info">
      {UserIcon}
      <span>
          {value.sector ? value.sector.replace(/&amp;/g, "&") : null}
        {value.study ? value.study.replace(/&amp;/g, "&") : null}
        </span>
    </div> : null}
    <div className="c-popup-accordeon-header__date">
      {value.date}
    </div>
  </div>)
}

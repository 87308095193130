import {Popup} from "react-leaflet";
import {ArrowIcon, DegreeIcon, MarkerTitle, ScheduleIcon, UserIcon} from "@js/components/map/utils/Icons";
import Item from "@js/components/map/components/Popup/Item";
import {useContext, useState} from "react";
import PostTypeContext from "@js/components/map/context/PostTypeContext";
import TransContext from "@js/components/map/context/TransContext";

export default function PopupCustom({item}) {
  const [itemSelected, setItemSelected] = useState(null)
  const {posttype, setPosttype} = useContext(PostTypeContext)
  const trans = useContext(TransContext);


  const handleSelect = (value) => {
    setItemSelected(value)
  }

  const resetSelect = () => {
    setTimeout(() => {
      setItemSelected(null)
    }, 100)
  }

  return (<Popup>
    <div className={`c-popup ${!itemSelected ? 'show' : 'hide'}`}>
      <div className={`c-popup__title ${!itemSelected ? 'show' : 'hide'}`}>
        {MarkerTitle}
        {item.title}
      </div>
      <div className={`c-popup-list ${!itemSelected ? 'open' : 'close'}`}>
        {item.items ? Object.entries(item.items).map(([key, value]) => (<Item onPress={(value) => handleSelect(value)} value={value}/>)) : null}
      </div>
      <div className={`c-popup-drawer ${itemSelected ? 'open' : null}`}>
        {itemSelected ? (<>
          <div className="c-popup-drawer__close" onClick={() => resetSelect()}>{ArrowIcon} {trans.map_popup_drawer_back}</div>
          <div className="c-popup-drawer-body">
            <div className="c-popup-drawer-body__domain">{itemSelected.theme.replace(/&amp;/g, "&")}</div>
            {itemSelected.client ? <div className="c-popup-drawer-body__title">{itemSelected.client.replace(/&amp;/g, "&")}</div> : null}
            <div className="c-popup-drawer-body__description">{itemSelected.description.replace(/&amp;/g, "&")}</div>
          </div>
          <div className="c-popup-drawer-header">
            <div className="c-popup-drawer-header-info">
              <div className="c-popup-drawer-header-info__title">{MarkerTitle} {itemSelected.country_name}</div>
              <div className="c-popup-drawer-header-info__data">{itemSelected.date}</div>
            </div>
            {!itemSelected.is_programme ? (<>
              <div className="c-popup-drawer-header-info">
                <div className="c-popup-drawer-header-info__title">{DegreeIcon}
                  <div className="label">{trans.map_popup_stage}</div>
                </div>
                <div className="c-popup-drawer-header-info__data">{itemSelected.nb_stagiaire > 1 ? itemSelected.nb_stagiaire : 'n/a'}</div>
              </div>
              <div className="c-popup-drawer-header-info">
                <div className="c-popup-drawer-header-info__title">{UserIcon}
                  <div className='label'>{trans.map_popup_expert}</div>
                </div>
                <div className="c-popup-drawer-header-info__data">{itemSelected.nb_experts > 1 ? itemSelected.nb_experts : 'n/a'}</div>
              </div>
              <div className="c-popup-drawer-header-info">
                <div className="c-popup-drawer-header-info__title">{ScheduleIcon}
                  <div className="label">{trans.map_popup_count_day}</div>
                </div>
                <div className="c-popup-drawer-header-info__data">{itemSelected.nb_h > 1 ? itemSelected.nb_h : 'n/a'}</div>
              </div>
            </>) : <div className="c-popup-drawer-header-info">
              <div className="c-popup-drawer-header-info__title">{DegreeIcon}
                <div className="label">{trans.map_popup_count_student}
                </div>
              </div>
              <div className="c-popup-drawer-header-info__data">{itemSelected.nb_students > 1 ? itemSelected.nb_students : 'n/a'}</div>
            </div>}
          </div>
          <div className="c-popup-drawer-footer">
            <div className="c-popup-drawer-footer-specifications">
              {itemSelected.sector ? <p>{trans.map_popup_sector}: {itemSelected.sector.replace(/&amp;/g, "&")}</p> : null}
              {itemSelected.study ? <p>{trans.map_popup_sector}: {itemSelected.study.replace(/&amp;/g, "&")}</p> : null}
              <p>{trans.map_popup_funding}: {itemSelected.funding}</p>
            </div>
          </div>
        </>) : null}
      </div>
    </div>
  </Popup>)
}

import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import marker from '../../../../icons/marker.svg'
import useApiFetch from "@js/components/map/utils/api";
import {API_URL, ENDPOINT} from "@js/components/map/utils/constant";
import {useContext, useEffect, useRef} from "react";
import FilterContext from "@js/components/map/context/FilterContext";
import {convertArrayToParams} from "@js/components/map/utils/convertArray";
import PostTypeContext from "@js/components/map/context/PostTypeContext";
import PopupCustom from "@js/components/map/components/Popup/PopupCustom";
import Loader from "@js/components/map/components/Loader";

export default function Map({isClosed}) {
  const [filter, setFilter] = useContext(FilterContext);
  const [posttype, setPosttype] = useContext(PostTypeContext);
  let params = convertArrayToParams(filter);

  const {res: resData, load: loadData, loading: loadingData} = useApiFetch('GET', API_URL + ENDPOINT + '?type=' + posttype + params)

  useEffect(() => {
    loadData();
  }, [filter])

  return (<div className="s-interactive-map__leaflet">
    <Leaflet data={resData} loading={loadingData} isClosed={isClosed}/>
    <Loader loading={loadingData}/>
  </div>)

}
const Leaflet = ({data, loading, isClosed}) => {

  let markerIcon = L.icon({
    iconUrl: marker, iconSize: [25, 41], iconAnchor: [15, 25], popupAnchor: [0, 70], iconRetinaUrl: marker,
  });

  const mapRef = useRef();

  return (<MapContainer center={[10.505, 0]} zoom={1.5} zoomControl={false} scrollWheelZoom={false} ref={mapRef}>
    <TileLayer
      attribution=''
      url="https://api.mapbox.com/styles/v1/akyosdev/ck0fckl0x00pi1co2mek3480w/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWt5b3NkZXYiLCJhIjoiY2pqanhqZXNsMGRzbDN2cDc1Z3o1Y2syciJ9.9sGP7SuDuFSV43jWfRG85g"
    />
    {!loading && data ? Object.entries(data).map(([key, item]) => {
      return (
        <Marker
          key={item.id}
          position={[item.lat, item.long]}
          icon={markerIcon}
          eventHandlers={{
            click: (e) => {
              let map = mapRef.current;

              let mql = window.matchMedia("(max-width: 992px)");

              const offsetLng = !isClosed && !mql.matches ? -40 : 0;
              const offsetLat = -20;
              console.log(isClosed, offsetLat, offsetLng, mql.matches)
              const newLatLng = L.latLng(e.latlng.lat + offsetLat, e.latlng.lng + offsetLng);

              map.flyTo(newLatLng, 3, {animate: true});
            },
          }}
        >
          <PopupCustom item={item}></PopupCustom>
        </Marker>
      )
    }) : null}

  </MapContainer>);
}

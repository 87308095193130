import Filter from "@js/components/map/components/Filter";
import {useEffect, useState} from "react";

export default function Sidebar({isProgram, title, updateClosed}) {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(!isClosed);
  }

  useEffect(() => {
    updateClosed(isClosed)
  }, [isClosed]);

  return (
    <div className={`s-interactive-map__sidebar map-sidebar ${isClosed ? 'close' : 'open'}`}>
      <div className="map-sidebar__title">{title}</div>
      <Filter isProgram={isProgram}/>

      <div className={`map-sidebar__openner ${isClosed ? 'close' : 'open'}`} onClick={handleClose}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.4608 10.2458C10.6162 10.0584 10.7035 9.80498 10.7035 9.5408C10.7035 9.27661 10.6162 9.02316 10.4608 8.8358L7.507 5.2458L10.4608 1.7058C10.6162 1.51844 10.7035 1.26498 10.7035 1.0008C10.7035 0.736612 10.6162 0.483161 10.4608 0.295798C10.3832 0.20207 10.291 0.127675 10.1893 0.0769067C10.0876 0.026138 9.97854 5.77065e-09 9.86839 0C9.75823 -5.7702e-09 9.64917 0.026138 9.54749 0.0769067C9.44581 0.127675 9.35352 0.20207 9.27595 0.295798L5.73804 4.5358C5.65983 4.62876 5.59776 4.73936 5.55539 4.86122C5.51303 4.98308 5.49122 5.11379 5.49122 5.2458C5.49122 5.37781 5.51303 5.50851 5.55539 5.63037C5.59776 5.75223 5.65983 5.86283 5.73804 5.9558L9.27595 10.2458C9.35352 10.3395 9.44581 10.4139 9.54749 10.4647C9.64917 10.5155 9.75823 10.5416 9.86839 10.5416C9.97854 10.5416 10.0876 10.5155 10.1893 10.4647C10.291 10.4139 10.3832 10.3395 10.4608 10.2458Z"
            fill="currentColor"/>
          <path
            d="M5.45434 10.2458C5.60975 10.0584 5.69698 9.80498 5.69698 9.5408C5.69698 9.27661 5.60975 9.02316 5.45434 8.8358L2.50052 5.2458L5.45434 1.7058C5.60975 1.51844 5.69698 1.26498 5.69698 1.0008C5.69698 0.736612 5.60975 0.483161 5.45434 0.295798C5.37677 0.20207 5.28448 0.127675 5.1828 0.0769067C5.08112 0.026138 4.97206 5.77065e-09 4.8619 0C4.75175 -5.7702e-09 4.64269 0.026138 4.54101 0.0769067C4.43933 0.127675 4.34704 0.20207 4.26947 0.295798L0.731559 4.5358C0.653351 4.62876 0.591275 4.73936 0.548913 4.86122C0.506551 4.98308 0.484741 5.11379 0.484741 5.2458C0.484741 5.37781 0.506551 5.50851 0.548913 5.63037C0.591275 5.75223 0.653351 5.86283 0.731559 5.9558L4.26947 10.2458C4.34704 10.3395 4.43933 10.4139 4.54101 10.4647C4.64269 10.5155 4.75175 10.5416 4.8619 10.5416C4.97206 10.5416 5.08112 10.5155 5.1828 10.4647C5.28448 10.4139 5.37677 10.3395 5.45434 10.2458Z"
            fill="currentColor"/>
        </svg>
      </div>
    </div>
  )
}

export class Archive {
  constructor() {
    this.container = document.querySelector('*[data-archive]')
    if (!this.container) {
      return;
    }
    this.type = this.container.getAttribute('data-archive')
    this.loader = this.container.querySelector('#c-loader')

    this.moreBtn = this.container.querySelector('*[data-archive-btn-more]');
    this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
    this.init()
  }

  init() {
    this.config = this.defaults()
    this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/akyos/v1/${this.type}`
    this.register()
  }

  defaults() {
    return {
      itemsperpage: parseInt(this.container.getAttribute('data-itemsperpage')),
      pagesoffset: parseInt(this.container.getAttribute('data-pageoffset')),
      currentpage: parseInt(this.container.getAttribute('data-pageoffset')), noMoreItems: false, currentTax: '',
    }
  }

  register() {
    this.moreBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.config.currentpage += 1;
      console.log(this.config.currentpage)

      this.fetch();
    })

  }

  fetch() {
    const params = new URLSearchParams();
    params.append('page', this.config.currentpage)
    params.append('per_page', this.config.itemsperpage)

    this.loader.removeAttribute('hidden')

    console.log(`${this.url}?${params.toString()}`)

    fetch(`${this.url}?${params.toString()}`)
      .then((r) => r.json())
      .then(r => {
        console.log(r)

        if (r.original.length === 0) {
          this.config.noMoreItems = true;
          this.moreBtn.setAttribute('hidden', '')
        } else {
          this.appendItems(r.original);
        }
        this.loader.setAttribute('hidden', 'hidden')

      })
      .catch(r => {
        this.config.noMoreItems = true;
      })
  }


  appendItems(items) {
    const template = document.querySelector('#archive-el');

    items.forEach((item) => {
      let clone = template.content.cloneNode(true);

      this.itemEvent(clone, item)
    })
  }

  itemEvent(clone, item) {


    switch (this.type) {
      case 'posts':
        this.itemPost(clone, item)
        break;
      case 'aky_job_offer':
        this.itemJobOffer(clone, item)
        break;

    }
  }

  itemPost(clone, item) {
    const link = clone.querySelector('.c-post a');
    const thumb = clone.querySelector('.c-post .c-image');
    const title = clone.querySelector('.c-post-content__title');
    const content = clone.querySelector('.c-post-content__excerpt')
    const cat = clone.querySelector('.c-post__category .c-image');


    console.log(item)

    const thumbnail = document.createElement('img')
    const termIcon = document.createElement('img')
    if (item?.thumbnail) {
      thumbnail.setAttribute('src', item?.thumbnail)
      thumb.appendChild(thumbnail)
    }

    if (item?.termIcon) {
      termIcon.setAttribute('src', item?.termIcon)
      cat.appendChild(termIcon)
    }

    if (title) {
      title.innerHTML = item.title
    }

    if (link) {
      link.href = item.link
    }

    if (content) {
      content.innerHTML = item.content
    }

    this.itemsContainer.appendChild(clone)
  }

  itemJobOffer(clone, item) {
    const link = clone.querySelector('.c-job-offer-footer a');
    const thumb = clone.querySelector('.c-job-offer-header .c-image');
    const title = clone.querySelector('.c-job-offer-body-title .c-title');
    const type = clone.querySelector('.c-job-offer-content__excerpt')
    const date = clone.querySelector('.c-job-offer-body-content__date span');
    const location = clone.querySelector('.c-job-offer__location');

    const thumbnail = document.createElement('img')
    if (item?.thumbnail) {
      thumbnail.setAttribute('src', item?.thumbnail)
      thumb.appendChild(thumbnail)
    }

    if (title) title.innerHTML = item.title
    if (link) link.href = item.link
    if (type) type.innerHTML = item.type
    if (date) date.innerHTML = item.date
    if (location) location.innerHTML = item.location

    this.itemsContainer.appendChild(clone)
  }
}

export class Tabs {
  constructor() {
    this._tabsHeader = document.querySelectorAll('.s-tabs-header-item')

    if (this._tabsHeader.length) {
      this.init();
    }
  }

  init() {
    this._tabsBody = document.querySelectorAll('.s-tabs-body-item')

    this._tabsHeader.forEach((tab, index) => {
      tab.addEventListener('click', (e) => {
        e.preventDefault()
        let target = tab.querySelector('button')

        this.remove()

        this._tabsBody[index].classList.add('active')
        target.classList.add('active')
      })
    })
  }

  remove() {
    this._tabsHeader.forEach((el) => {
      let elTarget = el.querySelector('button')
      elTarget.classList.remove('active')
    })

    this._tabsBody.forEach((el) => {
      el.classList.remove('active')
    })
  }
}

export const MarkerTitle = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M13.272 9.41016C13.272 10.471 12.8505 11.4884 12.1004 12.2386C11.3503 12.9887 10.3328 13.4102 9.27197 13.4102C8.21111 13.4102 7.19369 12.9887 6.44355 12.2386C5.6934 11.4884 5.27197 10.471 5.27197 9.41016C5.27197 8.34929 5.6934 7.33187 6.44355 6.58173C7.19369 5.83158 8.21111 5.41016 9.27197 5.41016C10.3328 5.41016 11.3503 5.83158 12.1004 6.58173C12.8505 7.33187 13.272 8.34929 13.272 9.41016ZM11.272 9.41016C11.272 9.94059 11.0613 10.4493 10.6862 10.8244C10.3111 11.1994 9.80241 11.4102 9.27197 11.4102C8.74154 11.4102 8.23283 11.1994 7.85776 10.8244C7.48269 10.4493 7.27197 9.94059 7.27197 9.41016C7.27197 8.87972 7.48269 8.37102 7.85776 7.99594C8.23283 7.62087 8.74154 7.41016 9.27197 7.41016C9.80241 7.41016 10.3111 7.62087 10.6862 7.99594C11.0613 8.37102 11.272 8.87972 11.272 9.41016Z"
          fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.794 15.6563C1.50484 14.4288 0.608609 12.8467 0.218639 11.1099C-0.17133 9.37311 -0.0375212 7.55967 0.603146 5.89893C1.24381 4.23818 2.36256 2.80471 3.81792 1.77979C5.27328 0.754864 6.99988 0.184523 8.77938 0.140889C10.5589 0.0972546 12.3114 0.582286 13.8152 1.53465C15.3191 2.48701 16.5067 3.86392 17.228 5.49127C17.9493 7.11863 18.1718 8.92332 17.8675 10.6771C17.5631 12.431 16.7455 14.0551 15.518 15.3443L9.31201 21.8623L2.794 15.6563ZM14.07 13.9653L9.243 19.0353L4.173 14.2083C3.17037 13.2536 2.47334 12.0231 2.17005 10.6723C1.86676 9.32144 1.97084 7.91103 2.46912 6.61937C2.9674 5.32771 3.8375 4.21281 4.96941 3.41564C6.10131 2.61848 7.44418 2.17486 8.8282 2.14088C10.2122 2.1069 11.5752 2.48408 12.7449 3.22473C13.9146 3.96538 14.8383 5.03624 15.3994 6.3019C15.9605 7.56755 16.1336 8.97115 15.897 10.3352C15.6604 11.6993 15.0246 12.9626 14.07 13.9653Z"
          fill="black"/>
  </svg>
)

export const DollarsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
    <path d="M5.48 7.9C3.21 7.31 2.48 6.7 2.48 5.75C2.48 4.66 3.49 3.9 5.18 3.9C6.96 3.9 7.62 4.75 7.68 6H9.89C9.82 4.28 8.77 2.7 6.68 2.19V0H3.68V2.16C1.74 2.58 0.18 3.84 0.18 5.77C0.18 8.08 2.09 9.23 4.88 9.9C7.38 10.5 7.88 11.38 7.88 12.31C7.88 13 7.39 14.1 5.18 14.1C3.12 14.1 2.31 13.18 2.2 12H0C0.12 14.19 1.76 15.42 3.68 15.83V18H6.68V15.85C8.63 15.48 10.18 14.35 10.18 12.3C10.18 9.46 7.75 8.49 5.48 7.9Z" fill="black"/>
  </svg>
)

export const DegreeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
    <path
      d="M13.8613 0.377272C13.3362 0.128223 12.7624 -0.000976563 12.1813 -0.000976562C11.6002 -0.000976562 11.0263 0.128223 10.5013 0.377272L0.753264 4.99527C0.573848 5.07991 0.423662 5.21601 0.321806 5.38624C0.219951 5.55648 0.171029 5.75316 0.181264 5.95127V12.0033C0.181264 12.2685 0.286621 12.5228 0.474157 12.7104C0.661693 12.8979 0.916047 13.0033 1.18126 13.0033C1.44648 13.0033 1.70083 12.8979 1.88837 12.7104C2.07591 12.5228 2.18126 12.2685 2.18126 12.0033V7.51127L4.18126 8.50327V14.0033C4.18129 14.1348 4.20727 14.2651 4.25772 14.3866C4.30817 14.5081 4.3821 14.6184 4.47526 14.7113L4.52526 14.7593L4.64526 14.8693C4.74526 14.9653 4.88993 15.0906 5.07926 15.2453C5.45326 15.5513 5.99326 15.9553 6.66726 16.3613C8.00726 17.1633 9.93526 18.0013 12.1813 18.0013C14.4273 18.0013 16.3573 17.1633 17.6953 16.3613C18.4152 15.9285 19.0929 15.4289 19.7193 14.8693L19.8393 14.7593L19.8853 14.7113H19.8893C19.9823 14.618 20.056 14.5073 20.1061 14.3854C20.1562 14.2636 20.1818 14.133 20.1813 14.0013V8.50127L23.6253 6.79327C23.7936 6.7099 23.9351 6.58078 24.0334 6.42072C24.1318 6.26065 24.1831 6.07612 24.1814 5.88826C24.1798 5.70039 24.1252 5.5168 24.024 5.35851C23.9228 5.20022 23.7791 5.07364 23.6093 4.99327L13.8613 0.377272ZM13.9253 11.6073L18.1813 9.49527V13.5593L18.0173 13.6993C17.5895 14.0452 17.1384 14.3613 16.6673 14.6453C15.5073 15.3413 13.9353 16.0033 12.1813 16.0033C10.4273 16.0033 8.85726 15.3433 7.69526 14.6453C7.16321 14.3231 6.65702 13.96 6.18126 13.5593V9.49527L10.4373 11.6073C11.5373 12.1513 12.8253 12.1513 13.9253 11.6073ZM13.0053 2.18527L20.8893 5.91927L13.0353 9.81527C12.7699 9.94707 12.4776 10.0157 12.1813 10.0157C11.8849 10.0157 11.5927 9.94707 11.3273 9.81527L3.47326 5.91927L11.3573 2.18527C11.6147 2.06294 11.8962 1.99946 12.1813 1.99946C12.4663 1.99946 12.7478 2.06294 13.0053 2.18527Z"
      fill="black"/>
  </svg>
)

export const UserIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path d="M9.18152 11C11.9429 11 14.1815 8.76142 14.1815 6C14.1815 3.23858 11.9429 1 9.18152 1C6.42009 1 4.18152 3.23858 4.18152 6C4.18152 8.76142 6.42009 11 9.18152 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.1815 19C17.1815 16.8783 16.3387 14.8434 14.8384 13.3431C13.3381 11.8429 11.3033 11 9.18152 11C7.05979 11 5.02496 11.8429 3.52466 13.3431C2.02437 14.8434 1.18152 16.8783 1.18152 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const ScheduleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M10.1816 2C14.5926 2 18.1816 5.589 18.1816 10C18.1816 14.411 14.5926 18 10.1816 18C5.77064 18 2.18164 14.411 2.18164 10C2.18164 5.589 5.77064 2 10.1816 2ZM10.1816 0C4.65864 0 0.181641 4.477 0.181641 10C0.181641 15.523 4.65864 20 10.1816 20C15.7046 20 20.1816 15.523 20.1816 10C20.1816 4.477 15.7046 0 10.1816 0ZM13.9816 13.4L11.1816 9.667V5H9.18164V10.333L12.3816 14.599L13.9816 13.4Z" fill="black"/>
  </svg>
)

export const ArrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M17.3948 8.20243L17.3948 10.1823L3.81837 10.1823L9.89949 16.2634L8.48528 17.6777L-5.96046e-06 9.19238L8.48528 0.707096L9.89949 2.12131L3.81837 8.20243L17.3948 8.20243Z" fill="black"/>
  </svg>
)

export const LoaderIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
    <circle fill='currentColor' stroke='currentColor' stroke-width='2' r='15' cx='40' cy='100'>
      <animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='-.4'></animate>
    </circle>
    <circle fill='currentColor' stroke='currentColor' stroke-width='2' r='15' cx='100' cy='100'>
      <animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='-.2'></animate>
    </circle>
    <circle fill='currentColor' stroke='currentColor' stroke-width='2' r='15' cx='160' cy='100'>
      <animate attributeName='opacity' calcMode='spline' dur='2' values='1;0;1;' keySplines='.5 0 .5 1;.5 0 .5 1' repeatCount='indefinite' begin='0'></animate>
    </circle>
  </svg>
)

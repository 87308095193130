import {Autocomplete, TextField} from "@mui/material";
import {API_URL, ENDPOINT} from "@js/components/map/utils/constant";
import useApiFetch from "@js/components/map/utils/api";
import {useEffect, useContext, useState} from "react";
import FilterContext from "@js/components/map/context/FilterContext";
import PostTypeContext from "@js/components/map/context/PostTypeContext";
import TransContext from "@js/components/map/context/TransContext";

export default function Filter({isProgram}) {
  const [posttype, setPosttype] = useContext(PostTypeContext);
  const trans = useContext(TransContext);

  const {res: country, load: loadCountry, loading: loadingCountry} = useApiFetch('GET', API_URL + ENDPOINT + '/country' + '?type=' + posttype);
  const {res: client, load: loadClient, loading: loadingClient} = useApiFetch('GET', API_URL + ENDPOINT + '/client' + '?type=' + posttype);
  // const {res: funding, load: loadFunding, loading: loadingFunding} = useApiFetch('GET', API_URL + ENDPOINT + '/funding' + '?type=' + posttype);
  const {res: study, load: loadStudy, loading: loadingStudy} = useApiFetch('GET', API_URL + ENDPOINT + '/study' + '?type=' + posttype);
  const {res: theme, load: loadTheme, loading: loadingTheme} = useApiFetch('GET', API_URL + ENDPOINT + '/theme' + '?type=' + posttype);
  const {res: sector, load: loadSector, loading: loadingSector} = useApiFetch('GET', API_URL + ENDPOINT + '/sector' + '?type=' + posttype);
  const [filter, setFilter] = useContext(FilterContext);
  const [reset, setReset] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    loadCountry();
    loadClient();
    // loadFunding();
    if (isProgram > 0) {
      loadStudy();

    } else {
      loadTheme();
      loadSector();
    }
  }, [isProgram]);

  const handleFilter = (value, label) => {
    if (value.length) {
      setShow(true);
    }

    if (filter) {
      setFilter({...filter, [label]: value})
    } else {
      setFilter({[label]: value})
    }
  }

  const handleReset = () => {
    setReset(!reset);
    setFilter({});
    setShow(false)
  }

  return (
    <>
      <div className="map-sidebar-filter">
        <div className="map-sidebar-filter__field">
          <Autocomplete
            onChange={(event, value) => {
              handleFilter(value, 'country')
            }}
            multiple
            clearOnBlur={true}
            loading={loadingCountry}
            id="tags-outlined"
            options={country}
            key={reset}
            getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}
            filterSelectedOptions
            noOptionsText={trans.filter_empty_country}
            renderInput={(params) => (<TextField
              {...params}
              label={trans.filter_label_country}
              placeholder={trans.filter_placeholder}
            />)}
          />
        </div>
        <div className="map-sidebar-filter__field">
          <Autocomplete
            onChange={(event, value) => {
              handleFilter(value, 'client')
            }}
            multiple
            loading={loadingClient}
            id="tags-outlined"
            options={client}
            key={reset}
            getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}
            filterSelectedOptions
            noOptionsText={trans.filter_empty_client}
            renderInput={(params) => (<TextField
              {...params}
              label={trans.filter_label_client}
              placeholder={trans.filter_placeholder}
            />)}
          />
        </div>
        {/*<div className="map-sidebar-filter__field">*/}
        {/*  <Autocomplete*/}
        {/*    onChange={(event, value) => {*/}
        {/*      handleFilter(value, 'funding')*/}
        {/*    }}*/}
        {/*    multiple*/}
        {/*    loading={loadingFunding}*/}
        {/*    id="tags-outlined"*/}
        {/*    options={funding}*/}
        {/*    key={reset}*/}
        {/*    getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}*/}
        {/*    filterSelectedOptions*/}
        {/*    noOptionsText={trans.filter_empty_funding}*/}
        {/*    renderInput={(params) => (<TextField*/}
        {/*      {...params}*/}
        {/*      label={trans.filter_label_funding}*/}
        {/*      placeholder={trans.filter_placeholder}*/}
        {/*    />)}*/}
        {/*  />*/}
        {/*</div>*/}
        {isProgram > 0 ?
          <div className="map-sidebar-filter__field">
            <Autocomplete
              onChange={(event, value) => {
                handleFilter(value, 'study')
              }}
              multiple
              id="tags-outlined"
              loading={loadingStudy}
              options={study}
              key={reset}
              getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}
              filterSelectedOptions
              noOptionsText={trans.filter_empty_study}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={trans.filter_label_study}
                  placeholder={trans.filter_placeholder}
                />
              )}
            />
          </div> :
          <>
            <div className="map-sidebar-filter__field">
              <Autocomplete
                onChange={(event, value) => {
                  handleFilter(value, 'theme')
                }}
                multiple
                id="tags-outlined"
                loading={loadingTheme}
                options={theme}
                key={reset}
                getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}
                filterSelectedOptions
                noOptionsText={trans.filter_empty_theme}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={trans.filter_label_theme}
                    placeholder={trans.filter_placeholder}
                  />
                )}
              />
            </div>
            <div className="map-sidebar-filter__field">
              <Autocomplete
                onChange={(event, value) => {
                  handleFilter(value, 'sector')
                }}
                multiple
                id="tags-outlined"
                loading={loadingSector}
                options={sector}
                key={reset}
                getOptionLabel={(option) => option.title.replace(/&amp;/g, "&")}
                filterSelectedOptions
                noOptionsText={trans.filter_empty_sector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={trans.filter_label_sector}
                    placeholder={trans.filter_placeholder}
                  />
                )}
              />
            </div>
          </>
        }
      </div>
      {show ?
        <div className="map-sidebar-filter-reset">
          <button className='btn btn--light btn--style-default' onClick={() => handleReset()}>Réinitialiser les filtres</button>
        </div>
        : null}
    </>
  );
}

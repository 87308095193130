import Swiper from 'swiper';
import {Pagination} from 'swiper/modules'

export class Slider {
  constructor() {
    this._slider = document.querySelector('.swiper')

    this.registerSlider()
  }

  registerSlider() {
    const slider = new Swiper(this._slider, {
      modules: [Pagination],
      slidesPerView: 1,
      autoHeight: true,
      loop: false,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
      },
    });
  }
}

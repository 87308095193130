export class ArrowCustom {
  constructor() {
    this._btn = document.querySelector('.forminator-button')
    this._li = document.querySelectorAll('.single .content ul li')
    this._liFiche = document.querySelectorAll('.c-card-fiche .content ul li')

    if (this._btn) {
      this.btn();
    }

    if (this._li.length || this._liFiche.length) {
      this.li();
    }
  }

  btn() {
    let el = document.createElement('div');

    el.innerHTML = "<svg width=\"13\" height=\"13\" viewBox=\"0 0 13 13\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
      "<path d=\"M1.4 13L0 11.6L9.6 2H1V0H13V12H11V3.4L1.4 13Z\" fill=\"currentColor\"/>\n" +
      "</svg>\n"

    this._btn.appendChild(el);
  }

  li() {
    this._li.forEach((item) => {
      this.appendArrow(item);
    })

    this._liFiche.forEach((item) => {
      this.appendArrow(item);
    })
  }

  appendArrow(item) {
    let el = document.createElement('div');
    el.classList.add('marker-icon')

    el.innerHTML = "<svg width=\"13\" height=\"13\" viewBox=\"0 0 13 13\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
      "<path d=\"M1.4 13L0 11.6L9.6 2H1V0H13V12H11V3.4L1.4 13Z\" fill=\"currentColor\"/>\n" +
      "</svg>\n"

    item.appendChild(el);
  }
}
